import { PaidPlans } from '@wix/ambassador-checkout-server/types';
import { Member } from '@wix/ambassador-members-ng-api/types';
import { ControllerFlowAPI, Experiments } from '@wix/yoshi-flow-editor';
import settingsParams from '../../components/BookingsForm/settingsParams';
import {
  BusinessInfo,
  Dialog,
  ServicePaymentDetails,
  SettingsSubTab,
  SettingsTab,
  TFunction,
} from '../../types/types';
import {
  getActiveSchedule,
  getServiceType,
  mapCatalogServiceToService,
  Service,
} from '../mappers/service.mapper';
import { ServiceType } from '@wix/bookings-uou-types';
import { GetActiveFeaturesResponse } from '@wix/ambassador-services-catalog-server/types';
import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';
import { FormApi } from '../../api/FormApi';
import { IWidgetControllerConfig } from '@wix/native-components-infra/dist/src/types/types';
import {
  BookingsQueryParams,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import { createDummyState } from '../dummies/dummy-data';
import {
  getDefaultPaymentOptionId,
  getFirstAvailablePaymentOptionId,
  isOfferedAsPricingPlanOnly,
} from '../payment/payment';
import { FormView, Submission } from '@wix/forms-ui/types';
import { getSessionValues } from '../storageFunctions';
import { EmptyStateErrorType, FormErrors } from '../../types/errors';
import { getErrorByType } from '../errors/errors';
import { CouponInfo } from '../../types/coupons';
import {
  BookingRequestKeyMappings,
  getFieldFromSchema,
} from '../mappers/form-submission.mapper';
import { mapServicePaymentDto } from '@wix/bookings-uou-mappers';
import { FormStatus } from '../../types/form-state';
import { SelectedPaymentOption } from '@wix/ambassador-bookings-gateway/types';
import { getCurrentTimezone } from '../timezone/timezone';
import { ListEligibleMembershipsResponse } from '@wix/ambassador-memberships-spi-host/http';
import { renderSEOTags } from '../seo/seo';
import { ListSlotsResponse } from '@wix/ambassador-calendar-server/http';

export type EditorContext = {
  isDummy: boolean;
  selectedSettingsTabId?: SettingsTab;
  selectedSettingsSubTabId?: SettingsSubTab;
};

export type FormState = {
  service: Service;
  isPricingPlanInstalled: boolean;
  isMemberAreaInstalled: boolean;
  couponInfo: CouponInfo;
  businessInfo: BusinessInfo;
  activeFeatures: GetActiveFeaturesResponse;
  slotAvailability: SlotAvailability;
  pricingPlanDetails?: PaidPlans;
  memberships?: ListEligibleMembershipsResponse;
  memberDetails?: Member;
  errors: FormErrors[];
  selectedPaymentOptionId: string;
  editorContext: EditorContext;
  status: FormStatus;
  overrideDefaultFieldsValues?: boolean;
  dialog?: Dialog;
  paymentDetails: ServicePaymentDetails;
  formInputs: FormInputs;
  selectedPaymentType: SelectedPaymentOption;
  isBookingsOnEcom: boolean;
};

export type FormInputs = {
  numberOfParticipants: number;
  email?: string;
};

export async function createInitialState({
  t,
  flowApi,
  wixSdkAdapter,
  formApi,
}: {
  t: TFunction;
  flowApi: ControllerFlowAPI;
  wixSdkAdapter: WixOOISDKAdapter;
  formApi: FormApi;
}): Promise<FormState> {
  const { settings, controllerConfig } = flowApi;

  if (wixSdkAdapter.isSSR()) {
    return {
      status: FormStatus.SSR,
    } as FormState;
  }

  const isUoUBookingsOnEconEnabled = flowApi.experiments.enabled(
    'specs.bookings.UoUBookingsOnEcom',
  );
  const isBookingsFormInternalApiErrorHandlerEnabled = flowApi.experiments.enabled(
    'specs.bookings.BookingsFormInternalApiErrorHandler',
  );
  const serviceId = getSessionValues(
    wixSdkAdapter,
    BookingsQueryParams.SERVICE_ID,
  );
  try {
    const isBookingsOnEcom = isUoUBookingsOnEconEnabled
      ? await formApi.isBookingsOnEcom({
          onError: (error) => {
            throw error;
          },
        })
      : false;
    if (
      wixSdkAdapter.isEditorMode() ||
      (wixSdkAdapter.isPreviewMode() && !serviceId)
    ) {
      const [
        catalogData,
        isPricingPlanInstalled,
        isMemberAreaInstalled,
      ] = await Promise.all([
        formApi.getCatalogData(),
        wixSdkAdapter.isPricingPlanInstalled().catch(() => false),
        wixSdkAdapter.isMemberAreaInstalled().catch(() => false),
      ]);

      if (isBookingsFormInternalApiErrorHandlerEnabled && !catalogData) {
        throw EmptyStateErrorType.INVALID_CATALOG_DATA;
      }

      return createDummyState({
        flowApi,
        businessInfo: catalogData.businessInfo,
        isMemberAreaInstalled,
        isPricingPlanInstalled,
        isBookingsOnEcom,
      });
    }

    const {
      areCouponsAvailable,
      catalogData,
      numberOfSessions,
      memberDetails,
      slotAvailability,
      pricingPlanDetails,
      memberships,
      isPricingPlanInstalled,
      isMemberAreaInstalled,
      errors,
    } = await fetchInitialData({
      formApi,
      controllerConfig,
      wixSdkAdapter,
      serviceId,
      isBookingsOnEcom,
      experiments: flowApi.experiments,
    });

    await renderSEOTags({ catalogData, wixSdkAdapter, t });

    const emptyStateError = getErrorByType({
      errorType: EmptyStateErrorType,
      errors,
    });

    if (emptyStateError) {
      throw emptyStateError;
    }

    const preFilledValues: Maybe<Submission> = getSessionValues(
      wixSdkAdapter,
      BookingsQueryParams.FILLED_FIELDS,
    );
    const service = mapCatalogServiceToService({
      catalogData: catalogData!,
      slotAvailability: slotAvailability!,
      pricingPlanDetails,
      memberships,
      preFilledValues,
      t,
      numberOfSessions: numberOfSessions!,
      serviceId,
    });

    const defaultPaymentOptionId = getDefaultPaymentOptionId({
      settings,
      servicePayment: service.payment,
      pricingPlanDetails,
      memberships,
      isPricingPlanInstalled: isPricingPlanInstalled!,
    });
    const numberOfParticipants = getNumberOfParticipantsValue(
      service.formSchema,
    );
    const email = getEmailValue(service.formSchema);
    const paymentDetails = mapServicePaymentDto(
      catalogData!.service,
    ) as ServicePaymentDetails;
    paymentDetails.totalPrice = paymentDetails.price;

    const selectedPaymentOptionId = getFirstAvailablePaymentOptionId({
      service,
      memberships,
      pricingPlanDetails,
      isPricingPlanInstalled: isPricingPlanInstalled!,
      businessInfo: catalogData!.businessInfo,
      selectedPaymentOptionId: defaultPaymentOptionId,
      t,
      settings,
      numberOfParticipants,
    })!;

    const selectedPaymentType = getDefaultPaymentType(
      settings,
      service?.paymentTypes,
    );

    return {
      activeFeatures: catalogData!.activeFeatures, // Todo: check if can remove it
      service,
      businessInfo: catalogData!.businessInfo,
      slotAvailability: slotAvailability!,
      isPricingPlanInstalled: isPricingPlanInstalled!,
      isMemberAreaInstalled: isMemberAreaInstalled!,
      pricingPlanDetails,
      memberships,
      memberDetails,
      errors,
      paymentDetails,
      selectedPaymentOptionId,
      couponInfo: {
        areCouponsAvailable: areCouponsAvailable!,
        isCouponInputDisplayed: false,
      },
      editorContext: {
        isDummy: false,
      },
      status: FormStatus.INITIALIZING,
      overrideDefaultFieldsValues: false,
      dialog: undefined,
      formInputs: {
        email,
        numberOfParticipants,
      },
      selectedPaymentType,
      isBookingsOnEcom,
    };
  } catch (formError) {
    return {
      errors: [formError],
    } as FormState;
  }
}

export const getDefaultPaymentType = (
  settings: ControllerFlowAPI['settings'],
  paymentTypes: SelectedPaymentOption[],
): SelectedPaymentOption => {
  const defaultPaymentType = settings.get(settingsParams.defaultPaymentTime);
  if (paymentTypes.includes(defaultPaymentType)) {
    return defaultPaymentType;
  }

  if (paymentTypes.length === 1) {
    return paymentTypes[0];
  }

  return paymentTypes.filter(
    (paymentType) => paymentType !== SelectedPaymentOption.MEMBERSHIP,
  )[0];
};

const fetchInitialData = async ({
  formApi,
  controllerConfig,
  wixSdkAdapter,
  serviceId,
  isBookingsOnEcom,
  experiments,
}: {
  formApi: FormApi;
  controllerConfig: IWidgetControllerConfig;
  wixSdkAdapter: WixOOISDKAdapter;
  serviceId?: string;
  isBookingsOnEcom: boolean;
  experiments: Experiments;
}) => {
  let errors: FormErrors[] = [];
  const isBookingsFormInternalApiErrorHandlerEnabled = experiments.enabled(
    'specs.bookings.BookingsFormInternalApiErrorHandler',
  );

  if (!serviceId) {
    if (isBookingsFormInternalApiErrorHandlerEnabled) {
      errors = [...errors, EmptyStateErrorType.INVALID_SERVICE_ID];
      return {
        errors,
      };
    }
    throw EmptyStateErrorType.INVALID_SERVICE_ID;
  }

  let slotAvailability = getSessionValues(
    wixSdkAdapter,
    BookingsQueryParams.AVAILABILITY_SLOT,
  );
  const resourceId = slotAvailability?.slot!.resource!.id!;
  const startTime = slotAvailability?.slot!.startDate!;
  const user = controllerConfig.wixCodeApi.user.currentUser;

  const [isPricingPlanInstalled, isMemberAreaInstalled] = await Promise.all([
    wixSdkAdapter.isPricingPlanInstalled().catch(() => false),
    wixSdkAdapter.isMemberAreaInstalled().catch(() => false),
  ]);
  const isLoggedInUser = user.loggedIn;
  const shouldGetMemberDetails = isMemberAreaInstalled && isLoggedInUser;
  const shouldGetPricingPlanDetails =
    isPricingPlanInstalled && isLoggedInUser && startTime;

  const [
    areCouponsAvailable,
    catalogData,
    memberDetails,
    pricingPlanDetails,
    memberships,
  ] = await Promise.all([
    formApi.areCouponsAvailableForService(),
    formApi.getCatalogData({
      serviceId,
      resourceId,
      onError: () => {
        errors = [...errors, EmptyStateErrorType.INVALID_CATALOG_DATA];
      },
    }),
    shouldGetMemberDetails
      ? formApi
          .getMemberDetails({
            id: user.id,
            onError: (error) => (errors = [...errors, error]),
          })
          .catch((e) => {
            errors = [...errors, e];
            return undefined;
          })
      : undefined,
    shouldGetPricingPlanDetails && !isBookingsOnEcom
      ? formApi
          .getPricingPlanDetails({
            serviceId,
            startTime,
            onError: (error) => (errors = [...errors, error]),
          })
          .catch((e) => {
            errors = [...errors, e];
            return undefined;
          })
      : undefined,
    shouldGetPricingPlanDetails && isBookingsOnEcom && !wixSdkAdapter.isOwner()
      ? formApi
          .listMemberships({
            serviceId,
            startTime,
            onError: (error) => (errors = [...errors, error]),
          })
          .catch((e) => {
            errors = [...errors, e];
            return undefined;
          })
      : undefined,
  ]);

  if (isBookingsFormInternalApiErrorHandlerEnabled && !catalogData) {
    return {
      errors,
    };
  }

  const activeSchedule = getActiveSchedule(catalogData?.service!);
  const scheduleId = activeSchedule?.id!;
  const firstSessionStart = activeSchedule?.firstSessionStart;
  const lastSessionEnd = activeSchedule?.lastSessionEnd;

  const type = getServiceType(activeSchedule);
  const isCourse = type === ServiceType.COURSE;
  let numberOfSessions = 1;

  if (isCourse) {
    if (!firstSessionStart) {
      if (isBookingsFormInternalApiErrorHandlerEnabled) {
        errors = [...errors, EmptyStateErrorType.COURSE_WITHOUT_SESSIONS];
        return {
          errors,
        };
      }
      throw EmptyStateErrorType.COURSE_WITHOUT_SESSIONS;
    }
    const [
      listSlots,
      courseAvailability,
      scheduleAvailability,
    ] = await Promise.all([
      lastSessionEnd
        ? formApi.getSlots({
            firstSessionStart: firstSessionStart!,
            lastSessionEnd,
            scheduleId,
            onError: (error) => (errors = [...errors, error]),
          })
        : {},
      !isBookingsOnEcom ? formApi.getAvailability({ scheduleId }) : undefined,
      isBookingsOnEcom
        ? formApi.getScheduleAvailability({ scheduleId })
        : undefined,
    ]);

    if (courseAvailability || scheduleAvailability) {
      const openSpots = isBookingsOnEcom
        ? scheduleAvailability?.openSpots
        : Number(courseAvailability!.capacity) -
          Number(courseAvailability!.totalNumberOfParticipants);
      const timezone = getCurrentTimezone({
        wixSdkAdapter,
        businessInfo: catalogData.businessInfo,
      });
      slotAvailability = {
        openSpots,
        slot: {
          startDate: firstSessionStart,
          endDate: lastSessionEnd,
          timezone,
        },
      };
    }

    numberOfSessions = listSlots?.slots?.length || 1;
  } else {
    if (!slotAvailability?.slot) {
      if (isBookingsFormInternalApiErrorHandlerEnabled) {
        errors = [...errors, EmptyStateErrorType.INVALID_SLOT_AVAILABILITY];
        return {
          errors,
        };
      }
      throw EmptyStateErrorType.INVALID_SLOT_AVAILABILITY;
    }
  }

  return {
    catalogData,
    numberOfSessions,
    memberDetails,
    slotAvailability,
    pricingPlanDetails,
    memberships,
    isPricingPlanInstalled,
    isMemberAreaInstalled,
    errors,
    areCouponsAvailable,
  };
};

const getNumberOfParticipantsValue = (formSchema: FormView): number => {
  const numberOfParticipants = getFieldFromSchema(
    formSchema,
    BookingRequestKeyMappings.NO_OF_PARTICIPANTS,
  )?.renderInfo?.displayProperties?.defaultValue;
  return (numberOfParticipants && Number(numberOfParticipants)) || 1;
};

const getEmailValue = (formSchema: FormView): string => {
  return getFieldFromSchema(formSchema, BookingRequestKeyMappings.EMAIL)
    ?.renderInfo?.displayProperties?.defaultValue;
};
